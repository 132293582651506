import gql from "graphql-tag";

const createStoreLead = gql`
  mutation storeLead($input: StoreLeadInput!) {
    storeLead(input: $input) {
      lead {
        id
        email
        source
      }
    }
  }
`;

export default createStoreLead;
