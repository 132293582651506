import React from 'react';
import './styles/ScrollDown.scss'

export default function ScrollDown() {
  const fieldRef = React.useRef(null);

  return (
      <div ref={fieldRef} className="ScrollDown w-100 d-flex justify-content-center align-items-center">
        <button className="btn btn-scroll px-5 py-3"
                onClick={e => fieldRef.current.scrollIntoView({behavior: 'smooth'})}>
          <div className="arrow">
            <span/>
            <span/>
            <span/>
          </div>
        </button>
      </div>
  )
}
